*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

:is(input:-ms-input-placeholder, textarea:-ms-input-placeholder), input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.hidden {
  display: none !important;
}

h2 {
  text-align: center;
  color: #23437d;
  font-size: 2em;
}

#content {
  color: #23437d;
  border-radius: .5rem;
  padding: 1rem;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  position: relative;
}

#content > h1 {
  text-align: center;
  margin-bottom: .75rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

#bike {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(35 67 125 / var(--tw-border-opacity));
  text-align: center;
  flex-direction: column;
  place-content: center;
  gap: .75rem;
  padding-bottom: .75rem;
  display: flex;
}

#bike > img {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  border-radius: .5rem;
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

#bike > .img-loading {
  aspect-ratio: 5 / 3;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

#bike > .img-loading {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

#bike > #bike-details {
  flex-direction: column;
  place-items: center;
  font-weight: 600;
  display: flex;
}

#bike > #bike-details > #brand-model.loading {
  width: 33.3333%;
}

#bike > #bike-details > #characteristics.loading {
  width: 50%;
}

#leasing-infos {
  text-align: center;
  flex-direction: column;
  place-items: center;
  margin-top: .75rem;
  display: flex;
}

#leasing-infos > #mark-wrap {
  flex-direction: column;
  place-items: center;
  width: 100%;
  padding-top: .75rem;
  padding-bottom: .75rem;
  display: flex;
}

#leasing-infos > #mark-wrap > #azfalte-mark-wrap.loading, #official-mark-wrap.loading {
  width: 40%;
}

#leasing-infos > #leasing-duration-wrap.loading {
  width: 33.3333%;
}

#leasing-infos > #release-date-wrap.loading {
  width: 80%;
}

#leasing-infos > #organization-wrap.loading {
  width: 33.3333%;
}

.bold {
  font-weight: 600;
}

.loading {
  --tw-bg-opacity: 1;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  border-radius: .375rem;
  height: 1rem;
  margin-top: .25rem;
  margin-bottom: .25rem;
  line-height: 1.75rem;
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.action-btn {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(255 81 19 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 81 19 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: 9999px;
  place-content: center;
  gap: .5rem;
  padding: .25rem .75rem;
  display: flex;
}

.action-btn:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 81 19 / var(--tw-text-opacity));
}

#assist-buttons {
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  margin-top: 1.25rem;
  display: flex;
}

#service-list-wrap {
  --tw-border-opacity: 1;
  border-width: 2px;
  border-color: rgb(35 67 125 / var(--tw-border-opacity));
  border-radius: .75rem;
  width: 100%;
  margin-top: .75rem;
  padding: .5rem;
}

#service-list-wrap > h2 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
  text-decoration-line: underline;
}

#service-list {
  width: 100%;
  overflow: hidden;
}

#service-list > .service-wrap {
  cursor: pointer;
}

#service-list .service-title {
  font-weight: 600;
}

#service-list .show-service-details {
  margin-left: .5rem;
  text-decoration-line: underline;
}

#service-modal {
  background-color: rgb(51 65 85 / var(--tw-bg-opacity));
  --tw-bg-opacity: .5;
  --tw-text-opacity: 1;
  color: rgb(35 67 125 / var(--tw-text-opacity));
  opacity: 0;
  align-items: center;
  width: 100vw;
  height: 100vh;
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: linear;
  display: none;
  position: fixed;
  top: 0;
}

#modal-txt-wrap {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  flex-direction: column;
  gap: .75rem;
  padding: .5rem;
  display: flex;
  position: relative;
}

#modal-txt-wrap > h2 {
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
}

#modal-txt-wrap > #close-modal {
  cursor: pointer;
  position: absolute;
  top: .75rem;
  right: 1.25rem;
}

/*# sourceMappingURL=index.a96845c7.css.map */
